import React from 'react';

import styled, { keyframes } from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';

import { DynamicImage, Text } from 'components';
import ContinueButton from '../QuizContinueButton';

const bigScroll = keyframes`
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 28.75rem;
  animation: ${bigScroll} 400ms ease-in 0s, ${show} 0.7s ease-in 0s backwards;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const StyledDynamicImage = styled(DynamicImage)`
  width: 100%;
  height: 100%;
  @media ${tablet} {
    max-width: 80%;
    margin: 0 auto;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  @media ${tablet} {
    padding-bottom: 5rem;
  }
`;

const StyledText = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.014rem;
  span {
    font-weight: 600;
  }
`;

type TextAndImgRowProps = {
  onContinue: () => void;
  image?: string;
  description?: string[];
  nextBtnTitle?: string;
};

const TextAndImgRow = ({
  image,
  description,
  nextBtnTitle,
  onContinue,
}: TextAndImgRowProps) => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <ImageContainer>
        <StyledDynamicImage src={image} alt="" />
      </ImageContainer>
      <TextContainer>
        {description?.map((item, index) => (
          <StyledText key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </TextContainer>
      <ContinueButton title={nextBtnTitle} onClick={onContinue} />
    </Container>
  );
};

export default TextAndImgRow;
